.fade-in-section-down-to-up {
    opacity: 0;
    transform: translateY(10vh);
    visibility: hidden;
    transition: opacity 1200ms ease-out, transform 600ms ease-out, visibility 1200ms ease-out;
    will-change: opacity, transform, visibility;
  }
  
  .fade-in-section-down-to-up.is-visible-down-to-up {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
  