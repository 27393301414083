.fade-in-section-left-to-right {
    opacity: 0;
    transform: translateX(-10vh);
    visibility: hidden;
    transition: opacity 1200ms ease-out, transform 600ms ease-out, visibility 1200ms ease-out;
    will-change: opacity, transform, visibility;
  }
  
  .fade-in-section-left-to-right.is-visible-left-to-right {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
  